var tooltips = document.querySelectorAll("[data-tooltip=\"tooltip\"]");
// console.log(tooltips);

if (tooltips.length > 0) {

  for (let index = 0; index < tooltips.length; index++) {
    const tool_element = tooltips[index];

    tool_element.addEventListener("click", function () {

      removeActiveToolTip();
      if (tool_element.classList.contains('active')) {
        tool_element.classList.remove("active");
      } else {
        tool_element.classList.add("active");
      }

      setTimeout(() => {
        // removeActiveToolTip();
        tool_element.classList.remove("active");
      }, 10000);

    });

  }

}

function removeActiveToolTip() {

  var tooltips = document.querySelectorAll("[data-tooltip=\"tooltip\"]");
  for (let index = 0; index < tooltips.length; index++) {
    const elem = tooltips[index];
    // console.log(elem);
    elem.classList.remove("active");
  }
}