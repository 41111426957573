const togg_nav = document.querySelector(".navbar-toggler");

if (togg_nav) {
  togg_nav.addEventListener("click", function () {
    console.log(this);

    var element = document.getElementById("navbartop");
    element.classList.toggle("bg-white");

  });
}

// dropdown-menu
$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
  }
  var $subMenu = $(this).next('.dropdown-menu');
  $subMenu.toggleClass('show');


  $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
    // $('.dropdown-item.active').removeClass('active');
    $('.dropdown-submenu .show').removeClass('show');
  });

  // $(this).parent();
  // $(this).parents('.dropdown-menu')
  // console.log();
  // $(this).toggleClass('active')



  return false;
});
